<script lang="ts" setup>
const isOpen = defineModel<boolean>()
</script>

<template>
  <div v-if="isOpen" class="fixed top-0 left-0 h-screen w-full bg-hos-blue-dark z-10">
    <div class="PagePadding mt-[67px] pt-5 overflow-y-auto h-[93vh]">
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-x-2 gap-5 md:gap-y-8">
        <CustomerModuleLayoutMegaMenuItem
          :title="$t('customerModule.navigation.start.title')"
          :description="$t('customerModule.navigation.start.description')"
          icon="material-symbols:garage-home-outline-rounded"
          @click="$router.push('/my-hos').then(() => isOpen = false)"
        />
        <CustomerModuleLayoutMegaMenuItem
          :title="$t('customerModule.navigation.profile.title')"
          :description="$t('customerModule.navigation.profile.description')"
          icon="material-symbols:person-edit-outline-rounded"
          @click="$router.push('/my-hos/profile').then(() => isOpen = false)"
        />
        <CustomerModuleLayoutMegaMenuItem
          :title="$t('customerModule.navigation.rentals.title')"
          :description="$t('customerModule.navigation.rentals.description')"
          icon="material-symbols:calendar-clock-outline"
          @click="$router.push('/my-hos/rental').then(() => isOpen = false)"
        />
        <CustomerModuleLayoutMegaMenuItem
          :title="$t('customerModule.navigation.sales.title')"
          :description="$t('customerModule.navigation.sales.description')"
          icon="material-symbols:shopping-cart-outline"
          @click="$router.push('/my-hos/sale').then(() => isOpen = false)"
        />
        <CustomerModuleLayoutMegaMenuItem
          :title="$t('customerModule.navigation.service-project.title')"
          :description="$t('customerModule.navigation.service-project.description')"
          icon="material-symbols:design-services-outline-rounded"
          @click="$router.push('/my-hos/service-project').then(() => isOpen = false)"
        />
      </div>
    </div>
  </div>
</template>
